@font-face {
  font-family: 'NotoSansCJKjpRegular';
  src: url('assets/fonts/NotoSansCJKjp-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'NotoSansCJKjpMedium';
  src: url('assets/fonts/NotoSansCJKjp-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NotoSansCJKjpBold';
  src: url('assets/fonts/NotoSansCJKjp-Bold.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'NotoSansCJKjpRegular',
    'NotoSansCJKjpMedium', 'NotoSansCJKjpBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/*** create by apple for SKII color&font ***/
.MuiAppBar-colorSecondary {
  background: darkred !important;
}
.MuiToolbar-root .MuiButtonBase-root {
  margin-left: 0.125em;
}
.MuiDrawer-root .MuiPaper-root {
  margin-top: 0.5em;
}
.MuiDrawer-root .MuiListItemIcon-root {
  color: brown;
}
.MuiDrawer-root .MuiTypography-root {
  color: black;
}
.MuiList-root .MuiListItemIcon-root {
  color: firebrick;
}
.MuiButton-textPrimary {
  color: firebrick;
}
.MuiButton-containedPrimary {
  background: firebrick;
}
.MuiToolbar-root .MuiButton-textPrimary {
  color: firebrick;
}
.simple-form .MuiGrid-item p.MuiFormHelperText-root {
  line-height: 0.75;
  margin-top: 0.1;
}
.simple-form .MuiGrid-item .MuiRadio-root {
  padding: 5px 9px;
}
.simple-form .MuiGrid-item .MuiIconButton-root,
.simple-form .MuiGrid-item .MuiSvgIcon-root {
  font-size: 1.15rem;
}
.simple-form .MuiGrid-item .MuiTypography-root {
  font-size: 0.85rem;
}
